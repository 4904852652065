@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
 
  background-color: #021526;
  color: #fff;
  scroll-behavior: smooth;
}

/* .morph-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}


.morph {
  position: absolute;
  background: radial-gradient(circle, #3498db, #00e676, #1abc9c);
  width: 200vw;
  height: 200vh;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  animation: morph 10s infinite ease-in-out;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
}


@keyframes morph {
  0% {
    transform: translate(-50%, -50%) scale(1.2);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background: radial-gradient(circle, #3498db, #00e676, #1abc9c);
  }
  25% {
    transform: translate(-60%, -40%) scale(1.1);
    clip-path: polygon(70% 20%, 80% 70%, 30% 90%, 10% 40%);
    background: radial-gradient(circle, #2980b9, #27ae60, #16a085);
  }
  50% {
    transform: translate(-45%, -55%) scale(1.3);
    clip-path: polygon(30% 10%, 90% 30%, 80% 90%, 20% 80%);
    background: radial-gradient(circle, #1e8449, #3498db, #27ae60);
  }
  75% {
    transform: translate(-40%, -60%) scale(1.15);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background: radial-gradient(circle, #00e676, #1abc9c, #2980b9);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background: radial-gradient(circle, #3498db, #00e676, #1abc9c);
  }
} */
h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  body,
  html {
    background-size: 20px 20px; 
  }
}

@media (max-width: 480px) {
  body,
  html {
    background-size: 15px 15px;
  }
}

/********************************************/


/***************home*************************/

.home-container {
  text-align: center;
  margin-top: 120px;
  height: 100%;
}

.animated-tagline {
  
  font-size: 80px;
  font-weight: bold;
  animation: fadeIn 2s ease-in-out;
}

.animated-subtext {
  font-size: 1.5rem;
  margin-top: 20px;
}

.animated-text {
  font-weight: bold;
  color: #db4734; 
  animation: fadeInText 1s ease-in-out infinite alternate;
}


@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInText {
  0% { opacity: 0.3; }
  100% { opacity: 1; }
}


/* Media Queries for Responsiveness */

/* For small screens, set font size to 20px */
@media (max-width: 736px) {
  .hero-heading {
    font-size: 40px !important;
  }
  .event_hero-heading{
    margin-top: -10px !important;
    font-size: 70px !important;

  }

  .hero-subheading {
    font-size: 25px !important;
  }

  .hero-span {
    font-size: 25px !important;
  }
}
@media screen and (min-device-width: 360px) and (max-device-width: 1200px)  {
  .hero-heading {
    font-size: 40px !important;
    
  }

  .hero-subheading {
    font-size: 20px !important;
  }

  .hero-span {
    font-size: 20px !important;
}
}

/* Basic styling for the canvas */
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: -10;
  pointer-events: none;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  canvas {
    background-color: rgba(0, 18, 51, 0.2); 
  }
}

/* Media Query for extra small screens (e.g., phones) */
@media screen and (min-device-width: 375px) and (max-device-width: 667px)  {
  canvas {
    width: 100% !important;
    height: 900px !important;
  }
}

@media screen and (min-device-width: 414px) and (max-device-width: 896px)  {
  canvas {
    width: 100% !important;
    height: 940px !important;
  }
}

@media screen and (min-device-width: 390px) and (max-device-width: 844px)  {
  canvas {
    width: 100% !important;
    height: 920px !important;
  }
}

@media screen and (min-device-width: 430px) and (max-device-width: 932px)  {
  canvas {
    width: 100% !important;
    height: 960px !important;
  }
}

@media screen and (min-device-width: 412px) and (max-device-width: 915px)  {
  canvas {
    width: 100% !important;
    height: 940px !important;
  }
}

@media screen and (min-device-width: 360px) and (max-device-width: 740px)  {
  canvas {
    width: 100% !important;
    height: 865px !important;
  }
}

/* Mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .animated-tagline {
    font-size: 1.8rem;
  }

  .animated-subtext {
    font-size: 1rem;
  }

  .home-container {
    margin-top: 0; /* No gap from the top */
  }
}

/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .animated-tagline {
    font-size: 2rem;
  }

  .animated-subtext {
    font-size: 1.2rem;
  }

  .home-container {
    margin-top: 0; /* No gap from the top */
  }
}

/* Small laptops (max-width: 1024px) */
@media (max-width: 1024px) {
  .animated-tagline {
    font-size: 2.2rem;
  }

  .animated-subtext {
    font-size: 1.3rem;
  }

  .home-container {
    margin-top: 40px; /* Slight gap on larger screens */
  }
}

/* Large screens (above 1024px) */
@media (min-width: 1025px) {
  .animated-tagline {
    font-size: 70px;
  }

  .animated-subtext {
    font-size: 50px;
  }

  .home-container {
    margin-top: 120px; /* Default gap for larger screens */
  }
}


/*****************Navbar**************/
.glass-effect {
  background: rgba(255, 255, 255, 0.1); /* Transparent white */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* Apply the glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari compatibility */
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.neon-border {
  border: 2px solid rgba(0, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 40px rgba(0, 255, 255, 0.5);
}

/* Hide glass effect and neon border on smaller screens */
@media only screen and (max-width: 1024px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}

@media only screen and (max-width: 375px) {
  .glass-effect {
    display: none !important;
  }
  .neon-border {
    display: none !important;
  }
}


.navbar {
  position: fixed;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #03346e, #0061a8);
  color: #fff;
  /* border-radius: 0px 0px 20px 20px; */
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  padding: 0 20px;
}

/* Logo Section */
.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  margin : 0px ;
}

.logo img {
  width: 150px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.logo img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 15px; /* Add spacing between items */
}

.nav-item {
  position: relative;
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  padding: 8px 12px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  background: transparent; /* Transparent for hover effects */
}

.nav-item a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #ffd700; /* Highlight color */
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.nav-item.active a {
  background: rgba(255, 255, 255, 0.3);
  color: #ffd700; /* Active state color */
}

/* Contribute Button */
.contribute-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contribute-button a {
  background: #ff5733;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0px 4px 10px rgba(255, 87, 51, 0.3);
  transition: all 0.3s ease-in-out;
}

.contribute-button a:hover {
  background: #e74c3c;
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0px 6px 12px rgba(255, 87, 51, 0.5);
}


/* Bottom Navbar for <= 1024px */
@media (max-width: 1024px) {
  .navbar {
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 85%;
    z-index: 1000;
    background-color: #03346e;
    color: #fff;
    padding: 10px 1rem;
    border-radius: 20px 0px 20px 0px;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
  }

  /* Hide Logo in Bottom Navbar */
  .navbar .logo {
    display: none;
  }

  /* Navbar Container Adjustments */
  .navbar-container {
    max-width: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  /* Bottom Navbar Menu */
  .navbar{
    display: none;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  /* Navbar Items */
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
    margin: 0 5px;
    position: relative;
  }

  .nav-item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 8px 0;
    color: #fff;
    transition: color 0.3s ease, transform 0.2s ease;
  }

  .nav-item a svg {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  .nav-item a:hover {
    color: #ffd700;
    text-decoration: none;
  }

  /* Hide Contribute Button in Bottom Navbar */
  .contribute-button {
    display: none;
  }
}

/* Very Small Screen Styles (<= 480px) */
@media (max-width: 480px) {
  .navbar{
    display: none;
  }
  .nav-menu li a {
    font-size: 0.7rem;
    padding: 6px 0;
  }

  .nav-menu li a svg {
    font-size: 18px;
    margin-bottom: 4px;
  }
}



/***************** About Page styles ******************/
/* General Styles */
/* .about-page {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
} */

/* Hero Section */
/* .hero-section {
  background: linear-gradient(120deg, #00a8ff, #00f2fe);
  color: #fff;
  text-align: center;
  padding: 50px 20px;
} */

/* .hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
} */

/* .hero-content p {
  font-size: 1.2rem;
} */

/* MVG Section */
/* .mvg-section {
  padding: 50px 20px;
  background-color: #03346e;
} */

/* .mvg-section h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
} */

/* .timeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
} */

/* .timeline-item {
  flex: 1 1 300px;
  background: #00a8ff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.timeline-item h3 {
  margin-bottom: 10px;
  color: #03346e;
} */

/* Services Section */
/* .services-section {
  padding: 50px 20px;
  background-color: #02479b;
}

.services-section h2 {
  text-align: center;
  margin-bottom: 30px;
}

.services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  flex: 1 1 250px;
  background: #00a8ff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-card h3 {
  margin-bottom: 10px;
  color: #03346e;
} */

@media (max-width: 768px){
  .aboutheading{
    margin-top: -100px;
  }
}

/***************Semester Cards****************/
@keyframes textFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sem {
  padding: 150px 0px 0px 0px;
  font-size: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  animation: textFadeIn 1s ease-in-out;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  text-decoration: none;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #03346e;
  transition: box-shadow 0.3s ease;
  animation: textFadeIn 1s ease-in-out;
}

.card-content {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .sem {
    font-size: 20px;
    margin-top: -120px;
  }
  
}

@media (max-width: 768px) {
  .sem {
    font-size: 20px;
    margin-top: -120px;
  }
  .cards-container{
    padding-bottom: 60px;
  }
  
}

/******** Cascading Dropdown *********/
.container {
  display: flex;
  justify-content: center; /* Centers the container horizontally */
  align-items: center; /* Centers the container vertically */
  flex-wrap: wrap; /* Allows items to wrap if space is insufficient */
  margin: 0 auto; /* Centers the container in its parent */
  padding: 20px;
  gap: 0px; /* Adds spacing between items */
  min-height: 100vh; /* Ensures full height for vertical centering */
  box-sizing: border-box;
  margin-bottom: -280px;
  
}

#left{
  justify-content:start;
  align-items: center;
  box-sizing: border-box;
  flex: 1; /* Allows sections to resize equally */
  min-width: 300px; /* Ensures the sections don't shrink too small */
  margin-top: -350px;

}
#right {
  display: flex;
  justify-content: end;
  align-items: center;
  box-sizing: border-box;
  flex: 1; /* Allows sections to resize equally */
  min-width: 300px; /* Ensures the sections don't shrink too small */
  margin-top: -350px;
}

#right img {
  animation: updown 3s ease-in-out infinite;
  max-width: 100%;
  height: auto; /* Maintains aspect ratio */
}

@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.select-container {
  width: 100%; /* Occupies full available width */
  max-width: 600px; /* Sets a maximum width for larger screens */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 20px; /* Adds padding for smaller screens */
}

.select {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.select select {
  width: 100%; /* Makes the dropdown width dynamic */
  height: auto;
  border-radius: 40px;
  -webkit-appearance: none;
  appearance: none;
  padding: 10px 20px;
  border: 1px solid #ccc;
  font-size: 16px;
  cursor: pointer;
  background-color: #007CB9;
}

/********* Responsive Dropdown *********/
@media (min-width: 1025px) {
  .container {
    padding-top: 50px; /* Reduced gap from the top */
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
    justify-content: flex-start; /* Align items flex-start for smaller devices */
    min-height: auto; /* Adjust height for smaller screens */
    padding-top: 20px; /* Additional adjustment for tablets */
  }

  #left {
    flex: none; /* Prevent resizing */
    width: 100%; /* Take full width in smaller screens */
    padding: 10px;
    margin-top: 30px;
  }

  #right {
    flex: none; /* Prevent resizing */
    width: 100%; /* Take full width in smaller screens */
    padding: 10px;
    margin-top: 30px;
  }

  #right img {
    width: 80%; /* Adjust size for smaller screens */
    margin: 0 auto; /* Center the image */
  }

  .select select {
    width: 90%; /* Adjust width for smaller screens */
    max-width: none; /* Remove the max-width constraint */
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
    flex-direction: column; /* Stack items vertically */
    gap: 15px;
    margin-bottom: 52px;
  }

  #right img {
    width: 100%; 
    height: auto;
  }

  .select select {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  #left {
    /* margin-top: 10px; */
    margin-top: -100px;
    margin-bottom: -400px;
  }

  .select select {
    padding: 8px;
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}



/*****************Regidtration and login CSS*****************/

.Auth_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.Auth_screen-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  padding: 10px;
  padding-top: 70px;
}

.Auth_smartphone_two {
  width: 320px;
  height: 568px;
  border: 16px solid #000;
  border-radius: 36px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-right: -200px;
  order: -1;
}

.Auth_smartphone {
  width: 320px;
  height: 568px;
  border: 16px solid #000;
  border-radius: 36px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  top: 45px;
}

.Auth_smartphone::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  width: 60px;
  height: 6px;
  background-color: #333;
  border-radius: 3px;
  transform: translateX(-50%);
}

.Auth_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Auth_form-section {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  margin-left: 0;
  color: #000;
  padding-top: 20px;
}

.Auth_form {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Auth_header {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 900;
}

.Auth_mb-3 {
  margin-bottom: 15px;
}

.Auth_form-control {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #000;
}

.Auth_btn-primary {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.Auth_btn-primary:hover {
  background-color: #0056b3;
}

.Auth_d-grid {
  text-align: center;
  justify-content: center;
}

.Auth_forgot-password {
  margin-top: 15px;
  text-align: center;
}

.Auth_forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.Auth_forgot-password a:hover {
  text-decoration: underline;
}

.Auth_login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.continue-p{
  display: flex;
  justify-content: center;
  align-items: center;
}

/****************Media Query Resgistration and login page **********************/

@media (min-width: 320px) and (max-width: 480px) {
  .Auth_screen-section {
    display: none;
  }

  .Auth_form-section {
    margin-left: 0;
    padding: 10px;
  }

  .Auth_form {
    max-width: 100%;
    padding: 15px;
    background-color: #021526;
    color: #fff;
    border: none;
  }

  .Auth_header {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .Auth_screen-section {
    display: none;
  }

  .Auth_form-section {
    margin-left: 0;
    padding: 15px;
  }

  .Auth_form {
    max-width: 100%;
    padding: 20px;
    background-color: #021526;
    color: #fff;
    border: none;
  }

  .Auth_header {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {

  .Auth_screen-section {
    display: none;
  }

  .Auth_form-section {
    margin-left: 0;
    padding: 10px;
  }

  .Auth_form {
    max-width: 100%;
    padding: 15px;
    background-color: #021526;
    color: #fff;
    border: none;
  }

  .Auth_header {
    font-size: 1.5rem;
  }
}


@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .Auth_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .Auth_screen-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    padding: 10px;
    padding-top: 70px;
  }
  
  .Auth_smartphone_two {
    width: 320px;
    height: 568px;
    border: 16px solid #000;
    border-radius: 36px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    margin-right: -200px;
    order: -1;
  }
  
  .Auth_smartphone {
    width: 320px;
    height: 568px;
    border: 16px solid #000;
    border-radius: 36px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    top: 45px;
  }
  
  .Auth_smartphone::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    width: 60px;
    height: 6px;
    background-color: #333;
    border-radius: 3px;
    transform: translateX(-50%);
  }
  
  .Auth_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .Auth_form-section {
    flex: 1.5;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    margin-left: 0;
    color: #000;
    padding-top: 20px;
  }
  
  .Auth_form {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .Auth_header {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 900;
  }
  
  .Auth_mb-3 {
    margin-bottom: 0px;
  }
  
  .Auth_form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .Auth_btn-primary {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .Auth_btn-primary:hover {
    background-color: #0056b3;
  }
  
  .Auth_d-grid {
    text-align: center;
    justify-content: center;
  }
  
  .Auth_forgot-password {
    margin-top: 15px;
    text-align: center;
  }
  
  .Auth_forgot-password a {
    color: #007bff;
    text-decoration: none;
  }
  
  .Auth_forgot-password a:hover {
    text-decoration: underline;
  }
  
  .Auth_login-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  
  .continue-p{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Auth_password-toggle-icon{
    width:50px;
  }
  
}


@media (min-width: 1441px) {
  
.Auth_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Auth_screen-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  padding: 10px;
  padding-top: 70px;
}

.Auth_smartphone_two {
  width: 320px;
  height: 568px;
  border: 16px solid #000;
  border-radius: 36px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-right: -200px;
  order: -1;
}

.Auth_smartphone {
  width: 320px;
  height: 568px;
  border: 16px solid #000;
  border-radius: 36px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  top: 45px;
}

.Auth_smartphone::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  width: 60px;
  height: 6px;
  background-color: #333;
  border-radius: 3px;
  transform: translateX(-50%);
}

.Auth_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Auth_form-section {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  margin-left: 0;
  color: #000;
  padding-top: 20px;
}

.Auth_form {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Auth_header {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 900;
}

.Auth_mb-3 {
  margin-bottom: 15px;
}

.Auth_form-control {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.Auth_btn-primary {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.Auth_btn-primary:hover {
  background-color: #0056b3;
}

.Auth_d-grid {
  text-align: center;
  justify-content: center;
}

.Auth_forgot-password {
  margin-top: 15px;
  text-align: center;
}

.Auth_forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.Auth_forgot-password a:hover {
  text-decoration: underline;
}

.Auth_login-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.continue-p{
  display: flex;
  justify-content: center;
  align-items: center;
}
}


/* General Styles for Footer */
/* footer {
  background: linear-gradient(90deg, #03346e, #0061a8);
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  border-top: 1px solid #e9ecef; 
  position: fixed;
  bottom: 0; 
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10; 
  height: 60px; 
}

footer p {
  margin: 0;
}


@media (max-width: 768px) {
  footer {
    padding: 15px;
    font-size: 14px;
    height: 50px;
  }
}


@media (max-width: 480px) {
  footer {
    padding: 10px;
    font-size: 12px;
    height: 40px; 
  }
} */





/* Profile container */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 10rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(1px 1px 20px #0077B5);
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid transparent; /* Border for animation */
  transition: border 0.3s ease, transform 0.3s ease; /* Animation for border and transform */
}

/* Profile container hover effect */
.profile-container:hover {
  border-color: #007bff; /* Change border color on hover */
  transform: translateY(-10px); /* Lift card up on hover */
  margin-top: 10rem;
}

/* Profile image styles */
.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #ddd;
  margin-bottom: 20px;
  transition: transform 0.3s ease; /* Animation for scaling */
}

/* Profile image hover effect */
.profile-image:hover {
  transform: scale(1.05); /* Scale image on hover */
}

/* Welcome message styles */
.welcome-message {
  margin: 20px 0;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s forwards 0.5s; /* Animation for fade-in */
}

/* User details */
.user-details {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
  text-align: center;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s forwards 1s; /* Animation for fade-in with delay */
}

/* Logout button */
.logout-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  max-width: 250px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Animation for background and scaling */
}

/* Button hover effect */
.logout-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Scale button on hover */
}

/* Upload button styles */
.upload-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  max-width: 250px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Animation for background and scaling */
}

/* Button hover effect */
.upload-button:hover {
  background-color: #218838;
  transform: scale(1.05); /* Scale button on hover */
}

/* File input (hidden) */
.file-input {
  display: none;
}

/* Progress bar container */
.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

/* Progress bar */
.progress-bar {
  width: 100%;
  height: 4px;
  background-color: red;
  transition: background-color 0.3s ease;
}

/* Loading text */
.loading-text {
  margin-top: 10px;
  font-size: 18px;
}

/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.confirm-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.confirm-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}

/* Animation for fade-in */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Responsive Design for Mobile & Tablet */
@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
    margin-top: 50px;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .welcome-message {
    font-size: 16px;
  }

  .user-details {
    font-size: 12px;
  }

  .logout-button,
  .upload-button {
    max-width: 200px;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .profile-container {
    margin-top: 30px;
    padding: 10px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .welcome-message {
    font-size: 14px;
  }

  .user-details {
    font-size: 12px;
  }

  .logout-button,
  .upload-button {
    max-width: 180px;
    margin-top: 15px;
  }
}


/*******************Contact Section****************/
/* .contact_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('./assets/contacts.jpg'); 
  background-repeat: no-repeat;
   background-size: cover;
  padding: 20px;
}

.Contact {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.Contact_h4 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

.contact_form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}

.contact_text,
.contact_input,
.contact_button {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.contact_text {
  resize: vertical;
  height: 120px;
}

.contact_button {
  background-color: #ff4081;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact_button:hover {
  background-color: #e73370;
}

.contact_social-icons {
  color: #555;
  margin: 10px 5px;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.contact_social-icons:hover {
  color: #ff4081;
}
.contact_div{
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-container{
    background-size: 500px 300px; 
  }
  .Contact {
    padding: 15px;
  }

  .Contact_h4 {
    font-size: 1.5rem;
  }

  .contact_text,
  .contact_input,
  .contact_button {
    font-size: 0.9rem;
  }
} */

.contactus{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0; /* No spacing between items */
  
}


.contactusimg{
  margin-left: 40px;
}
.contact_container{
  margin-top:150px;
}

.contact_div{
  margin-left:auto;
  margin-right:auto;
  display:flex;
  flex-direction:row;
  align-items:center; 
  justify-content:center; 
}
.contactusdisplay{
  display: flex;
}


@media (max-width: 768px){

  /* .ccontactusfield{
    display: flex;
    flex-direction:row !important;
  } */

  .contact_div{
    display:flex;
    flex-direction:column;
  }

  .contact_container{
    margin-top:-90px;
  }
  .contactusimg{
    width:30%;
    height:300px;
    margin-left: 2px;
  }
  .contactusform{
    width:100%;
    height:40%;
    background-color: transparent;
    border: none;
    margin-top: -10px;
  }

  .contactuslabel{
    font-size: 11px;
    color: #fff;
  }
  .contactusbutton{
    width: 100px;
    display: flex; /* Enable Flexbox for alignment */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    margin: auto; /* Center the button within its container */
  }

  /* .contactusdisplay{
    display: flex;
    flex-direction: column;
    width: 100%;
  } */

  .contactusplaceholder{
    font-size: 10px;
  }
  /* .contactuslab{
    width:95%;
  }  */
  
}
/************Social Icon***************/
.social_h3 {
  font-size: 80px;
  font-weight: bold;
}

.social_p {
  font-size: 20px;
  margin-top: -50px;
}

.social_bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

/* Social Buttons */
.social_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.social_button1, .social_button2 {
  width: 180px;
  height: 50px;
  font-size: 20px;
  border-radius: 10px;
}

.social_button1 {
  background-color: #28D146;
}

.social_button2 {
  background: linear-gradient(45deg, #4285F4, #BB001B, #EA4335, #FBBC05, #34A853);
}

.social_p1 {
  font: bold 40px;
}

.Social_cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_card {
  position: relative;
  width: 50%;
}

.insta_img {
  width: 400px;
  height: auto;
}

/* Join Button */
.join_button {
  position: absolute;
  top: 72%;
  left: 30%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #4285F4, #BB001B, #EA4335, #FBBC05, #34A853);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.join_button:hover {
  background: linear-gradient(#4285F4);
  transform: translate(-50%, -50%) scale(1.1);
}

.join_button:active {
  background-color: blue;
  transform: translate(-50%, -50%) scale(0.95);
}

/* Responsive Styles */
@media screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .Social_cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .social_h3 {
    font-size: 25px;
  }
  .socialicon_p{
    font-size: 20px !important;
  }
  .social_p {
    font-size: 12px;
    margin: 20px;
  }
  .social_button1, .social_button2 {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .insta_img {
    width: 260px;
  }
  .join_button {
    font-size: 10px;
    padding: 8px 16px;
    left:90px;
    width:120px;
    font-weight: bold;
  }


}


@media screen and (min-device-width: 414px) and (max-device-width: 896px) {
  .Social_cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .social_h3 {
    font-size: 25px;
  }
  .socialicon_p{
    font-size: 20px !important;
  }
  .social_p {
    font-size: 12px;
    margin: 20px;
  }
  .social_button1, .social_button2 {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .insta_img {
    width: 260px;
  }
  .join_button {
    font-size: 10px;
    padding: 8px 16px;
    left:100px;
    width:120px;
    font-weight: bold;
  }


}


@media screen and (min-device-width: 390px) and (max-device-width: 844px) {
  .Social_cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .social_h3 {
    font-size: 25px;
  }
  .socialicon_p{
    font-size: 20px !important;
  }
  .social_p {
    font-size: 12px;
    margin: 20px;
  }
  .social_button1, .social_button2 {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .insta_img {
    width: 260px;
  }
  .join_button {
    font-size: 10px;
    padding: 8px 16px;
    left:100px;
    width:120px;
    font-weight: bold;
  }


}


@media screen and (min-device-width: 430px) and (max-device-width: 932px) {
  .Social_cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .social_h3 {
    font-size: 25px;
  }
  .socialicon_p{
    font-size: 20px !important;
  }
  .social_p {
    font-size: 12px;
    margin: 20px;
  }
  .social_button1, .social_button2 {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .insta_img {
    width: 260px;
  }
  .join_button {
    font-size: 10px;
    padding: 8px 16px;
    left:105px;
    width:120px;
    font-weight: bold;
  }


}


@media screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .Social_cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .social_h3 {
    font-size: 25px;
  }
  .socialicon_p{
    font-size: 15px !important;
  }
  .social_p {
    font-size: 12px;
    margin: 20px;
  }
  .social_button1, .social_button2 {
    width: 180px;
    height: 35px;
    font-size: 14px;
  }
  .insta_img {
    width: 260px;
  }
  .join_button {
    font-size: 10px;
    padding: 8px 16px;
    left:90px;
    width:120px;
    font-weight: bold;
  }


}


/*************** PDF Viewer ****************/

.pdf-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);  /* Dark overlay */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.pdf-viewer-content {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
  max-width: 1200px;
  height: 80%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.pdf-viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #03346e;
  color: white;
}

.pdf-viewer-header h2 {
  font-size: 1.5rem;
  margin: 0;
}

.view-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #0056b3;
}

.pdf-viewer-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
}

.pdf-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

.pdf-container embed {
  width: 100%;
  height: 500px;
  border-radius: 8px;
}

.pdf-viewer button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.pdf-viewer button.close:hover {
  background-color: #ff1a1a;
}

/*************** Responsive Styles ****************/

@media (max-width: 1024px) {
  .pdf-viewer-content {
    width: 90%;
    height: 90%;
  }

  .view-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .pdf-container embed {
    height: 400px;
  }

  .pdf-viewer-header h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .pdf-viewer-content {
    width: 95%;
    height: 90%;
  }

  .view-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .pdf-container embed {
    height: 350px;
  }

  .pdf-viewer-header h2 {
    font-size: 1rem;
  }

  .pdf-viewer-body {
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .pdf-viewer-content {
    width: 100%;
    height: 90%;
  }

  .view-button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .pdf-container embed {
    height: 300px;
  }

  .pdf-viewer-header h2 {
    font-size: 1rem;
  }

  .pdf-viewer-body {
    padding: 5px;
  }

  button.close {
    font-size: 14px;
    padding: 8px 15px;
  }
}


/********youtube playlist*********/

.ac-component {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.content-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
  padding: 20px;
}

.nav-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.tab-button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-content {
  margin-top: 20px;
}

.notes-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.note-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: calc(50% - 10px); /* Updated to fit two cards per row */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.video-section {
  margin-top: 20px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.video-card {
  position: relative;
  margin: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.video-card iframe {
  width: 100%;
  height: 250px;
  border-radius: 8px;
}

.video-card h5 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.delete-video-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.delete-video-btn:hover {
  opacity: 1;
}

.add-video-button {
  display: block;
  margin: 20px auto;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-video-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .note-card {
    width: 100%;
  }

  .tab-button {
    font-size: 14px;
  }
}

/* Styling for the video grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.video-card iframe {
  width: 100%;
  height: 250px;
  border-radius: 8px;
}

/* Delete button styling */
.delete-video-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.delete-video-btn:hover {
  opacity: 1;
}

/* Responsive tweaks */
@media (max-width: 768px) {
  .note-card {
    width: 100%;
  }

  .tab-button {
    font-size: 14px;
  }

  .video-card iframe {
    height: 200px;
  }

  .add-video-button {
    font-size: 14px;
  }
}



/**************/
/***************Semester Cards****************/
@keyframes textFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sem {
  padding: 150px 0px 0px 0px;
  font-size: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  animation: textFadeIn 1s ease-in-out;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  text-decoration: none;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #03346e;
  transition: box-shadow 0.3s ease;
  animation: textFadeIn 1s ease-in-out;
}

.card-content {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile */
@media (max-width: 480px) {
  .sem {
    font-size: 20px;
    margin-top: -120px;
  }
  
}

@media (max-width: 768px) {
  .sem {
    font-size: 20px;
    margin-top: -120px;
  }
  .cards-container {
    padding-bottom: 60px;
  }
}
/**********Tabs***************/
.tabs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.tabs-header {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent tab wrapping */
  width: 100%; /* Full width */
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start; /* Align tabs to the start */
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.tabs-header::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}

.tabs-scroll {
  display: inline-flex; /* Tabs align horizontally */
  scroll-behavior: smooth; /* Smooth scrolling */
  width: 100%; /* Match parent container width */
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  justify-content: center;
}

.tab.active {
  background-color: #03346e;
  color: #fff;
  border-radius: 20px;
}

.highlight {
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  /* background: rgba(255, 255, 255, 0.1); */
  z-index: -1;
}

.tabs-content {
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 1px solid #ddd;
  width: 100%;
}

/* Small Screen Fix for Horizontal Scroll */
@media (max-width: 480px) {
  .tabs-header {
    justify-content: flex-start;
    padding-left: 10px;
    overflow-x: auto; /* Ensure scrolling is enabled */
    width:90vw;
    margin-left: 50px;

  }

  .tabs-scroll {
    flex-shrink: 0; /* Prevent shrinking */
    overflow-x: auto; /* Enable horizontal scrolling */
  }

  .tabs-content {
    width: 100%;
    padding: 10px;
  }
}

/* Tablet Media Query */
@media (max-width: 768px) {
  .tabs-container {
    padding: 20px;
  }

  .tabs-header {
    justify-content: flex-start;
    padding-left: 15px;
  }

  .tabs-scroll {
    padding: 0 10px;
  }
}


/**********Dropdowns***************/
.accordions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.accordion {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px; 
  background-color: #03346e;
  color: white;
  padding: 0 10px;
  box-sizing: border-box;
}

.accordion-title {
  font-size: 16px;
}

.accordion-toggle {
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.accordion-content {
  max-height: 0; 
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #f9f9f9;
  padding: 0 10px;
  box-sizing: border-box;
}

.accordion-content p {
  margin: 10px 0;
  text-align: justify;
}

/* When accordion is active */
.accordion-content {
  max-height: 200px; 
  background-color: #021526;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  justify-content: space-between;
}


@keyframes scrollText {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/************Applied_Physics_I ************/
.Ap_I {
  padding-top: 80px;
  
}

.Ap_I h1 {
  display: flex;
  font-size: 2em;
  margin-bottom: 20px;
  justify-content: center;
}

/*************Media query for tabs, dropdown***************/
@media (max-width: 1200px) {

}

@media (max-width: 768px) {


  .Ap_I {
    padding-top: 60px; 
  }

  .Ap_I h1 {
    font-size: 1.5em; 
    margin-bottom: 10px; 
  }
}

@media (max-width: 480px) {
  .tabs-container {
    padding: 10px;
    width:19rem;
  }

  .tabs-header {
    flex-direction: column; 
    overflow-x: hidden; 
  }

  .tab {
    padding: 8px 10px;
  }

  .tabs-content {
    padding: 10px;
    margin-left: 5rem ;
    gap: 10px;
    width:20rem;
  }

  .accordions-container {
    width: 100%;
  }

  .accordion-header {
    height: 40px; 
    font-size: 12px;
  }

  .accordion-toggle {
    font-size: 18px; 
  }

  .accordion-content {
    max-height: 1000px;
  }

  .accordion-content p {
    margin: 10px 0;
    text-align: justify;
    font-size: 12px;
  }

  .Ap_I {
    padding-top: 40px;
  }

  .Ap_I h1 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
}

/*****************/

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.close-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.close-button:hover {
  background-color: darkred;
}

/*****************Form Styles******************/
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  margin: 0 auto;
  padding: 30px;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  /* background-color: #f9f9f9; */
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  color: #fff;
}

button {
  /* background-color: #03346e; */
  color: white;
  border: none;
  padding: 12px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
}

/* button:hover {
  background-color: #021526;
} */

@media (max-width: 768px) {
  form {
    width: 80%;
    padding: 20px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea {
    font-size: 14px;
  }

  button {
    padding: 10px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  form {
    width: 90%;
    padding: 15px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea {
    font-size: 14px;
  }

  button {
    padding: 8px;
    font-size: 14px;
  }
}



.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  width: 65px;
  height: 65px;

}

.fullscreen-button:hover {
  background: rgba(0, 0, 0, 0.8);
}


.Subjectname {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: xx-large;
  padding-top: 250px; /* Default padding for larger screens */
}

/* Remove padding for small screens */
@media screen and (max-width: 768px) {
  .Subjectname {
    padding-top: 0; /* Remove padding for smaller screens */
  }
}




/* ResultManager.css */

.result-manager-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.title {
  text-align: center;
}

.welcome {
  text-align: center;
}

.admin-panel,
.search-panel {
  margin-bottom: 20px;
}

.text-input,
.file-input {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.upload-button,
.search-button {
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.enrollment-input {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.error-message {
  color: red;
  text-align: center;
}

.result-container {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.result-table th,
.result-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.result-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

@media (max-width: 768px) {
  .result-manager-container {
    padding: 10px;
  }

  .upload-button,
  .search-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .text-input,
  .enrollment-input {
    padding: 8px;
  }
}










/* src/components/StudentProfile.css */

.profile-container {
  display: flex;
  justify-content:flex-start;
  padding: 20px;
}

.profile-card {
  background-color: #f4f4f9;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  overflow: hidden;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.profile-photo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-details h2 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
}

.profile-details .info {
  font-size: 1.1rem;
  color: #555;
}

.profile-body {
  display: flex;
  flex-direction: column;
}

.results, .contact {
  margin-bottom: 20px;
}

.results h3, .contact h3 {
  font-size: 1.5rem;
  color: #333;
}

.results ul {
  list-style-type: none;
  padding-left: 0;
}

.results li {
  font-size: 1rem;
  color: #555;
}

.contact a {
  display: block;
  color: #007bff;
  text-decoration: none;
  margin-top: 5px;
}

.contact a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
  }

  .profile-photo {
    width: 100px;
    height: 100px;
  }

  .profile-details h2 {
    font-size: 1.5rem;
  }

  .profile-body {
    padding: 10px;
  }

  .results ul li {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .profile-card {
    padding: 15px;
  }

  .profile-details h2 {
    font-size: 1.3rem;
  }

  .results ul li {
    font-size: 0.85rem;
  }

  .contact a {
    font-size: 0.9rem;
  }
}

/**********************Post*******************/
.post_h1{
  text-align: center;
  color: #ccc;
  margin-bottom: 10px;
} 
 .post_h2 {
  text-align: justify;
  color: #ccc;
 } .post_h3 {
  text-align: justify;
  color: #ccc;
}

.post_view{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem; /* Space between icon and text */
  padding: 0.5rem 0.75rem; /* Button padding */
  background-color: #3B82F6; /* Blue background */
  color: white !important; /* White text */
  font-size: 20px; /* Small text */
  font-weight: 500; /* Medium font weight */
  border-radius: 0.375rem; /* Rounded corners */
  transition: background-color 0.2s; /* Smooth transition on hover */
}
.adminbutton{
  display: flex;
  justify-content: center;
  align-items: center;
}

.post_tutorial-cards-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #000;
}

.post_search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.post_search-bar input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.post_admin-panel {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.post_admin-panel input, .post_admin-panel textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.post_admin-panel button {
  background-color: #007bff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.post_admin-panel button:hover {
  background-color: #0056b3;
}

.post_tutorial-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.post_tutorial-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post_tutorial-card h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #007bff;
}

.post_tutorial-card p {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.6;
}


.post_tutorial-card button {
  background-color: #007bff;
  width:150px;
  color: #000;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.post_tutorial-card button:hover {
  background-color: #0056b3;
}

.post_fullscreen-content img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.post_admin-actions button {
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s ease;
}

.post_admin-actions button:hover {
  background-color: #0056b3;
  color: #fff;
}

.post_edit-modal {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.post_edit-modal input, .post_edit-modal textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.tutorial-detail {
  display: flex;
  transition: all 0.3s ease;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  transition: width 0.3s ease;
}

.sidebar:hover {
  width: 260px;
}

.sidebar h3 {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  align-items: center;
}

.sidebar ul {
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar li {
  border: #000 2px solid;
  list-style: none;
  border-radius: 10px;
}

.topic-content {
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, #1a1a1a, #333333); /* Gradient background for modern feel */
  margin-left: 200px;
  padding: 20px;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  color: #fff; /* White text for contrast */
  font-family: 'Roboto', sans-serif; /* Modern sans-serif font */
  transition: margin-left 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  padding-top: 100px;
}

.topic-h2{
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic-content h2{
  color: red;
}
.topic-content h1,

.topic-content h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #f1f1f1;
  margin-bottom: 15px;
}

.topic-content p {
  font-size: 1.1rem;
  line-height: 2;
  margin-bottom: 20px;
  color: #d3d3d3; 
}

.topic-content img {
  display: flex;
  margin: 0 auto; /* Centers the image horizontally */
  width: 40%;
  height: auto; /* Maintains aspect ratio */
  justify-content: center;
  align-items: center;
}


.topic-content ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.topic-content li {
  font-size: 1rem;
  color: #d3d3d3;
  list-style: disc;
  margin-bottom: 10px;
}

.topic-content a {
  color: #4e9fff; /* Light blue for links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.topic-content a:hover {
  color: #fff; /* Change to white when hovered */
}

.topic-content .button {
  background: #4e9fff; /* Button with a modern blue */
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease, transform 0.2s ease;
}

.topic-content .button:hover {
  background: #3577d8;
  transform: scale(1.05); 
}

.topic-content .card {
  background: #222222;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.topic-content .card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


/* Adjustments when sidebar is hovered */
.sidebar:hover ~ .topic-content {
  margin-left: 260px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .Post_h1{
    margin-top: -0px !important;
    margin-bottom: 50px;
  }
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    overflow-x: auto; 
    overflow-y: hidden;
    backdrop-filter: blur(5px);
    white-space: nowrap;
    padding: 10px 0;
    z-index: 1000;
    box-sizing: border-box;
    background-color: #fffde7;
  }

  .sidebar:hover {
    width: 100%;
  }

  .sidebar h3 {
    display: none; 
  }

  .sidebar ul {
    width:150px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    list-style: none;
  }

  .sidebar li {
    border: #000 2px solid;
    list-style: none;
    border-radius: 10px;
    padding: 10px;
    flex: 0 0 auto;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    transition: background 0.3s ease;
    width:90%;
  }

  .sidebar li:hover {
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
  }

 
  .topic-content {
    width: 100%; 
    margin: 0; 
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    box-sizing: border-box; 
    overflow: hidden; 
    transition: background-color 0.3s ease;
  }


  .sidebar:hover ~ .topic-content {
    margin-left: 0; 
  }

  .topic-h2{
    padding-top: 80px;
  }
}




/************Users*******************/
.dashboard {
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.header {
  text-align: center;
  padding: 20px;
  transform-style: preserve-3d; 
  perspective: 1200px; 
}

.header h1 {
  font-size: 3rem;
  color: #fff;
  transform: rotateY(0deg);
  transition: transform 0.5s ease-in-out;
}

.stat {
  font-size: 2rem;
  margin: 10px 0;
  color: #fff;
  transform: rotateY(0deg);
  transition: transform 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 2.5rem;
  }

  .stat {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: 15px;
  }

  .header h1 {
    font-size: 2rem;
  }

  .stat {
    font-size: 1.2rem;
  }
}


/***************CSS file (PrivacyPolicy.css)********************/

.privacy-policy-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.privacy-policy-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.privacy-policy-intro {
  text-align: justify;
  margin-bottom: 2rem;
}

.privacy-policy-section {
  margin-bottom: 1.5rem;
}

.privacy-policy-section h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.privacy-policy-section p {
  text-align: justify;
  line-height: 1.6;
}

.privacy-policy-footer {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
}


/******************Feedback ************************/
.star {
  font-size: 80px;
  cursor: pointer;
  color: gray;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8), 0px 8px 15px rgba(0, 0, 0, 0.6);
}
.star.gold {
  color: gold;
}
@media (max-width: 768px) {
  .star {
    font-size: 50px;
  }
}
@media (max-width: 480px) {
  .star {
    font-size: 30px;
  }
}


.swiper-slide {
  flex: 0 0 33.33%; 
}

/* Responsive Media Queries */


@media (max-width: 768px) {
  .swiper-slide {
    flex: 0 0 50%; 
  }
}


@media (max-width: 600px) {
  .swiper-slide {
    flex: 0 0 100%; 
  }

  
  .swiper-slide .relative {
    padding: 4px; 
    height: auto;
  }

  .swiper-slide img {
    width: 40px; 
    height: 40px;
  }

  .swiper-slide .text-center {
    font-size: 14px; 
  }

  .swiper-slide .font-semibold {
    font-size: 16px; 
  }
}





/*********Blog component************/

.blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post {
  margin-bottom: 30px;
}

.post h3 {
  color: #666;
  margin-bottom: 5px;
}

.post h2 {
  margin-top: 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 20px 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post-form {
  background: #f5f5f5;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
}

.post-form div {
  margin-bottom: 15px;
}

.post-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.post-form input[type="text"],
.post-form input[type="url"],
.post-form textarea,
.post-form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.post-form textarea {
  min-height: 100px;
}

.read-more {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}